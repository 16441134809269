import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import SubmitButton from "../components/SubmitButton";
import Footer from "../components/Footer/Footer";
import { Call, LocationCity } from "@mui/icons-material";
import contactImg from "../images/contact-back.jpg";
const ContactUs = () => {
  const [submitted, setSubmitted] = React.useState(false);

  return (
    <>
      <div id='google_translate_element'></div>
      <Helmet>
        <title>Contact | vKover</title>
      </Helmet>
      <Header isTransparent />
      <Box
        mb={"20px"}
        sx={{
          // apply gradient over image
          backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${contactImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "400px",
        }}
      >
        <Box width='460px' margin={"auto"} py={"80px"} textAlign={"center"}>
          <Typography
            color='#fff'
            variant='h5'
            fontSize={"40px"}
            fontWeight={"600"}
          >
            Contact
          </Typography>
        </Box>
      </Box>
      <Container
        maxWidth='lg'
        sx={{
          mt: "-160px",
        }}
      >
        <Paper
          sx={{
            padding: "40px",
          }}
        >
          <Grid
            container
            spacing={"40px"}
            mb={"40px"}
            justifyContent={"center"}
          >
            <Grid item md={6} sm={6} xs={12}>
              <Paper
                sx={{
                  padding: "20px",
                  height: "195px",
                  marginBottom: "40px",
                }}
                elevation={4}
              >
                <Box
                  gap='20px'
                  display='flex'
                  alignItems={"center"}
                  height='100%'
                  mb={2}
                >
                  <Box>
                    <Call
                      sx={{
                        color: "#016D91 !important",
                      }}
                      fontSize='large'
                    />
                  </Box>
                  <Box>
                    <Typography variant='body1' color='text.secondary'>
                      Call us directly at
                    </Typography>
                    <Typography
                      component={"a"}
                      href='tel:+919288009111'
                      sx={{
                        color: "#016D91 !important",
                        fontWeight: "600",
                        fontSize: "22px",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      +91 92880 09111
                    </Typography>
                  </Box>
                </Box>
              </Paper>
              <Paper
                sx={{
                  padding: "20px",
                  height: "195px",
                }}
                elevation={4}
              >
                <Box
                  gap='20px'
                  display='flex'
                  height={"100%"}
                  alignItems={"center"}
                  mb={2}
                >
                  <LocationCity
                    sx={{
                      color: "#016D91 !important",
                    }}
                    fontSize='large'
                  />
                  <Box>
                    <Typography variant='body1'>
                      2nd Floor,Mariyam Tower
                    </Typography>
                    <Typography variant='body1'>
                      K K Road,Ernakulam,Kerala
                    </Typography>
                    <Typography variant='body1'>682 017</Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              {submitted ? (
                <Paper
                  sx={{
                    padding: "20px",
                    height: "100%",
                  }}
                >
                  <Stack
                    textAlign={"center"}
                    mb={4}
                    mt={4}
                    px={2}
                    py={4}
                    spacing={2}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant='h6'
                      fontWeight={"600"}
                      color='text.secondary'
                    >
                      Thank you for contacting us. We will get back to you soon.
                    </Typography>
                  </Stack>
                </Paper>
              ) : (
                <Paper
                  sx={{
                    padding: "20px",
                  }}
                  elevation={4}
                >
                  <Typography
                    variant='h6'
                    fontWeight={"600"}
                    color='text.secondary'
                  >
                    Get a call
                  </Typography>
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      email: "",
                      phone: "",
                      pincode: "",
                    }}
                    onSubmit={(values) => {
                      fetch("https://apibeta.vkover.com/api/SubmitLeadData", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          FNAME: values.first_name,
                          LNAME: values.last_name,
                          EMAIL: values.email,
                          MOBILE: values.phone,
                          PINCODE: values.pincode,
                          LOB: "ALL",
                        }),
                      }).then(() => {
                        setSubmitted(true);
                      });
                    }}
                    validationSchema={yup.object().shape({
                      first_name: yup
                        .string()
                        .required("First name is required"),
                      last_name: yup.string().required("Last name is required"),
                      email: yup
                        .string()
                        .email("Email is invalid")
                        .required("Email is required"),
                      phone: yup
                        .string()
                        .required("Phone is required")
                        .matches(/^[5-9]{1}[0-9]{9}$/, "Phone is invalid"),
                      pincode: yup
                        .string()
                        .required("Pincode is required")
                        .matches(/^[1-9]{1}[0-9]{5}$/, "Pincode is invalid"),
                    })}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextInput label='First Name' name='first_name' />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <TextInput label='Last Name' name='last_name' />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextInput label='Email' name='email' />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextInput
                          label='Phone'
                          name='phone'
                          inputProps={{
                            maxLength: "10",
                          }}
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextInput
                          label='Pincode'
                          name='pincode'
                          inputMode='numeric'
                          inputProps={{
                            maxLength: "10",
                          }}
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <SubmitButton>Submit</SubmitButton>
                      </Grid>
                    </Grid>
                  </Formik>
                </Paper>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
